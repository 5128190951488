import $ from 'jquery'

export function setupConferenceLinkRight() {
  $('.conference-link-right').each(function(i, element) {
    const el = $(element)
    const color = el.data('bg')

    if (color) {
      el.find('a').css('background-color', color)
    } else {
      const img = el.data('bg-img')

      if (img) {
        const value = 'url(' + img + ')'
        el.find('a').css('background-image', value)
      }
    }
  })
}
