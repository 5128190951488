import {
  BREAKPOINT,
  getWindowWidth
} from './menu'

export function setupHeader() {
  $('.conference-header').each(function (index) {
    var header = $(this)
    var imageId = parseInt(header.data('image'), 10)
    var isMenuTop = header.data('type') === 'menu-top'

    var orgH = header.data('height') ? parseInt(header.data('height'), 10) : 400
    var h = orgH
    if (isMenuTop) {
      var menuTopH = header.find('.conference-header__logo-menu').outerHeight()

      h = orgH + menuTopH
    }

    header.find('.conference-header__bg').css({
      'min-height': h
    })

    // obraz tła
    var orgH = header.data('height') ? parseInt(header.data('height'), 10) : 400
    if (imageId) {
      var w = 1140
      var ext = header.data('extension') ? header.data('extension') : 'jpg'
      var domain = header.data('domain')
      var url =
        domain +
        '/cache/media/' +
        imageId +
        '/fit/center/' +
        1 +
        '/' +
        w +
        '-' +
        orgH +
        '.' +
        ext
      header.find('.conference-header__bg').css({
        'background-image': 'url(' + url + ')',
        'background-position': 'bottom center',
        'background-repeat': 'no-repeat'
      })
    }

    // kolor tła
    var bg = header.data('bg')
    if (bg) {
      header.find('.conference-header__bg').css({
        'background-color': bg
      })
    }
    var text = header.data('text')
   
     
    if (text) {
      header.find('.conference-header__subtitle').css({
        color: text
         
      })
      header.find('.conference-header__title').css({
        color: text
      })
      header.find('.conference-header__localisation').css({
        color: text
      })
      header.find('.conference-header__time').css({
        color: text
      })
      header.find('.conference-header__localisation svg').css({
        fill: text
      })
      header.find('.conference-header__time svg').css({
        fill: text
      })
    }
    var textBg = header.data('text-bg')
    if (textBg) {
      header.find('.conference-header__subtitle').css({
        background: textBg
      })
      header.find('.conference-header__title').css({
        background: textBg
      })
    }

    var line1 = header.data('line-1')
    var line2 = header.data('line-2')
    var line3 = header.data('line-3')
    if(line1){
      header.find('.conference-header__title--1').css({
        'font-size': line1+'px',
        'line-height': line1+'px',
      })
    }
    if(line2){
      header.find('.conference-header__title--2').css({
        'font-size': line2+'px',
        'line-height': line2+'px',
      })
    }
    if(line3){
      header.find('.conference-header__title--3').css({
        'font-size': line3+'px',
        'line-height': line3+'px',
      })
    }
    const w = getWindowWidth();
    if (w > BREAKPOINT) {
      setTimeout(function () {
        // fix position absolute top
        var lvl1 = $('.conference-header__menu .lvl-0 .lvl-1')
        var topA = lvl1
          .parent()
          .find('>a')
          .outerHeight()
        lvl1.css('top', topA)
      }, 100)
    }
  })

  // menu w desktop po hover, w <768 po kliknięciu rozwija
}