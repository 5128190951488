import $ from 'jquery'
export const BREAKPOINT = 991

export const getWindowWidth = () => {
  // const w = window.visualViewport ? window.visualViewport.width : window.innerWidth
  const w = $(window).width();
  // alert(w)
  return w
}
export const setupMenu = () => {
  const menu = $('.conference-header__menu-c')
  const menuToogle = $('.main-menu-trigger')
  const menuToogleOpenIcon = $('.main-menu-trigger__open')
  const menuToogleOpenClose = $('.main-menu-trigger__close')
  const openClass = 'opened'

  const hideMenu = () => {
    menu.slideUp()
    menu.removeClass(openClass)
  }
  const showMenu = () => {
    menu.slideDown()
    menu.addClass(openClass)
  }

  const checkSize = () => {
    const w = getWindowWidth()

    if (w <= BREAKPOINT) {
      console.log('pokazmy menu toggle')
      // pokazmy menu toogle, ukryjmy menu,
      menuToogle.addClass(openClass)
      menu
        .find('.conference-header__menu')
        .addClass('conference-header__menu--compact')

      // zmienmy ikone
      // menuToogleOpenIcon.addClass('.main-menu-trigger--active')
      // menuToogleOpenClose.addClass('.main-menu-trigger--hidden')
      hideMenu()
    } else {
      // showMenu()
    }
  }

  menuToogle.on('click', function (e) {
    e.preventDefault()
    if (menu.hasClass(openClass)) {

      menuToogleOpenIcon.addClass('main-menu-trigger--active').removeClass('main-menu-trigger--hidden')
      menuToogleOpenClose.addClass('main-menu-trigger--hidden').removeClass('main-menu-trigger--active')

      hideMenu()
    } else {

      menuToogleOpenIcon.addClass('main-menu-trigger--hidden').removeClass('main-menu-trigger--active')
      menuToogleOpenClose.addClass('main-menu-trigger--active').removeClass('main-menu-trigger--hidden')


      showMenu()
    }
  })

  // to jest wywolywane nawet jesli nie zmieni sie rozmiar na mobilnych
  // $(window).on('resize', e => {
  //   checkSize()
  // })
  const w = getWindowWidth()
  if (w <= BREAKPOINT) {
    // jednorazowo
    //  menu przeniesc pod header jesli jest top
    const menuTop = $('.conference-header--menu-top .conference-header__menu')
    if (menuTop) {
      $('.conference-header__menu--bottom-place').append(menuTop)
    }
    $('.conference-header__menu--bottom-place').append(
      $('.conference-header__right')
    )
  }
  // on load
  checkSize()
}