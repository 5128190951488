import $ from 'jquery'
import tooltipster from 'tooltipster'

export const setupTooltips = () => {
  const baseOptions = {
    theme: 'tooltipster-noir tooltipster-parki',
    animation: 'fade',
    delay: 100
  }
  $('.has-tooltip').tooltipster(baseOptions)
  $('.has-tooltip-search').tooltipster(
    Object.assign(baseOptions, {
      trigger: 'click',
      side: 'bottom',
      theme: baseOptions.theme + ' tooltipster-noborder',
      interactive: true,
      functionBefore: function(instance, helper) {
        instance.on('state', e => {
          if (e.state === 'stable') {
            $('#search-content')
              .find('.search-input')[0]
              .focus()
          }
        })
      }
    })
  )
}
