import "@babel/polyfill";
import './css/index.scss'
import $ from 'jquery'

import {
  optionsWithNav,
  optionsLogotypes,
  setupCarousel
} from './js/carousel.js'
import lightbox from './js/lightbox.js'
import {
  setupMenu
} from './js/menu.js'
import {
  setupActivePage
} from './js/setup-active-page.js'
import {
  setupLightbox
} from './js/setup-lightbox.js'
import {
  setupTooltips
} from './js/setup-tooltips.js'
import {
  setupConferenceLinkRight
} from './js/conference-link-right.js'
import {
  setupIconsBlock
} from './js/icons-blocks.js'
import {
  showMessages
} from './js/message.js'
import {
  setupHeader
} from './js/header'
import {
  setupCookiePolicy
} from './js/cookies'

import {
  setupSiteLook
} from './js/site-look'

// lightbox plugin
export const lightboxE = lightbox

function onScroll() {
  // console.log(window.pageYOffset)
  const el = $('.go-top')
  const visible = 'go-top--visible'
  if (window.pageYOffset > 400) {
    el.addClass(visible)
  } else {
    el.removeClass(visible)
  }
}

function skipToContent() {
  if (window.innerWidth < 768) {
    // const $container = $('body')
    const $scrollTo = $('a[name="main-content"]')
    if ($scrollTo.length) {
      // console.log($scrollTo.offset().top)
      // const top =
      //   $scrollTo.offset().top -
      //   $container.offset().top +
      //   $container.scrollTop()
      const top = $scrollTo.offset().top
      // console.log(top)
      // $container.scrollTop(top)
      // console.log(window.pageYOffset)
      window.scrollTo(0, top)
    }
  }
}

window.onload = function () {
  // header
  setupHeader()

}

$(function () {
  setupActivePage()

  // site look
  setupSiteLook()
  // cookies
  setupCookiePolicy(window.COOKIE_POLICY_URL || "/")
  // main menu
  setupMenu()
  // tooltips
  setupTooltips()
  // lightbox
  setupLightbox()


  setupConferenceLinkRight()
  // messages
  showMessages()
  // icons block
  setupIconsBlock()
  // owl
  // setupCarousel('.has-slider', { responsive: { 0: { items: 1 } } })
  setupCarousel('.has-slider', optionsWithNav)
  setupCarousel('.has-slider-2', optionsLogotypes)
  // setupCarousel('.files__slider', optionsWithNav)
  // setupCarousel('.gallery-list__slider', optionsWithNav)
  // go-top
  window.addEventListener('scroll', onScroll)
  onScroll()
  $('.go-top').on('click', function (e) {
    e.preventDefault()
    window.scrollTo(0, 0)
  })
  // przeskocz odrazu do treści jesli jest on mobile w<768
  setTimeout(function () {
    skipToContent()
  }, 200)
})