import './owl.carousel'
import { leftArrow, rightArrow } from './icons'

const defaultOptions = {
  nav: false,
  loop: true,
  autoplay: true,
  autoplayTimeout: 4000,

  autoplayHoverPause: true,
  onInitialized: function() {
    $('.has-lightbox').magnificPopup({
      type: 'image',
      titleSrc: 'title',
      gallery: {
        enabled: true
      }
      // other options
    })
  }
}

export const setupCarousel = (selector, options = {}) => {
  const newOptions = Object.assign({}, { ...defaultOptions }, { ...options })

  $(selector).owlCarousel(newOptions)
}

const navText = [leftArrow, rightArrow]

export const optionsWithNav = {
  responsive: {
    0: {
      items: 1
    },
    690: {
      items: 1
    },
    768: {
      items: 2
    },
    992: {
      items: 3
    }
  },
  items: 3,
  dots: false,
  loop: false,
  autoplay: true,
  autoplayTimeout: 3000,
  nav: true,
  navText: navText
}
export const optionsLogotypes = {
  responsive: {
    0: {
      items: 1
    },
    690: {
      items: 1
    },
    768: {
      items: 1
    },
    992: {
      items: 1
    }
  },
  autoWidth: false,
  items: 1,
  dots: true,
  loop: true,
  autoplay: true,
  autoplayTimeout: 5000,
  nav: false,
  navText: navText
}
