function cookiepolicy() {
  $('#cookie-policy').hide()
  document.cookie = '_cookiepolicy=true; path=/; max-age=2592000;'
  return false
}

export function setupCookiePolicy(cookiePolicyUrl) {
  var text =
    'Strona korzysta z plików cookies w celu realizacji usług i zgodnie z ' +
    ' <a class="cookie-url" target="_blank" href="' +
    cookiePolicyUrl +
    '">Polityką Plików Cookies</a>. ' +
    'Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce. '
  var link =
    '<a href="#" class="btn btn-primary cookie-url-accept">' +
    'OK, rozumiem' +
    '</a>'
  var cookiePolicyText =
    '<div class="cookie-policy" id="cookie-policy"><div class="container cookie-policy-inner"><div class="row"><div class="col-md-8">' +
    text +
    '</div><div class="col-md-4">' +
    link +
    '</div></div></div></div>'

  if (document.cookie.indexOf('_cookiepolicy=true') === -1) {
    $('body')
      .append(cookiePolicyText)
      .css('margin-bottom', '90px')
    $('.cookie-url-accept').on('click', function(e) {
      e.preventDefault()
      cookiepolicy()
    })
  }
}
