export const setupLightbox = () => {
  $.extend(true, $.magnificPopup.defaults, {
    tClose: 'Zamknij (Esc)', // Alt text on close button
    tLoading: 'Chwileczkę...', // Text that is displayed during loading. Can contain %curr% and %total% keys
    gallery: {
      tPrev: 'Poprzedni (Klawisz strzałka w lewo)', // Alt text on left arrow
      tNext: 'Następny (Klawisz strzałka w prawo)', // Alt text on right arrow
      tCounter: '%curr% z %total%' // Markup for "1 of 7" counter
    },
    image: {
      tError: '<a href="%url%">The image</a> nie mogłem pobrać.' // Error message when image could not be loaded
    },
    ajax: {
      tError: '<a href="%url%">Treść</a> nie mogła być pobrana.' // Error message when ajax request failed
    }
  })
  $('.has-lightbox').magnificPopup({
    type: 'image',
    titleSrc: 'title',
    gallery: { enabled: true }
    // other options
  })
}
