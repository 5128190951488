import $ from 'jquery'

export const setupActivePage = () => {
  if (window.currentPageId) {
    $("li[data-page=" + window.currentPageId + "]").addClass("active");
  }
  if (window.currentPageParentId) {
    $("li[data-page=" + window.currentPageParentId + "]").addClass("active");
  }
};
