export const setupSiteLook = () => {
  var css = ''
  if (window.siteLook) {
    for (var i = 0; i < window.siteLook.length; i++) {
      css = css + window.siteLook[i]
    }
    var head = document.head || document.getElementsByTagName('head')[0]
    var style = document.createElement('style')

    style.type = 'text/css'
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css
    } else {
      style.appendChild(document.createTextNode(css))
    }

    head.appendChild(style)
  }
}
