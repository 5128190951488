const TOP = 63;
var count = 0;
export function showMessages() {
  const els = $('.conference-message')

const elements = []
  els.each(function(i) {
    const el = $(this)
    if (shouldBeShown(el)) {
      const to = parseDate(el.data('to'))
      if (!to) {
        return false
      }
      // console.log(to)
      const message = JSON.parse(el.data('message'))

      const now = new Date()

      var time = datediff(now, to)
      time = parseInt(time, 10)
      // console.log(time)
      if (time > 1) {
        time = time + ' dni'
      }
      if (time === 1) {
        time = time + ' dzień'
      }
      if (time < 1) {
        var hours = datediffhours(now, to)
        hours = parseInt(hours, 10)
        // console.log(hours)
        if (hours === 1) {
          time = hours + ' godzinę'
        }
        if (hours > 1 && hours < 5) {
          time = hours + ' godziny'
        }
        if (hours >= 5) {
          time = hours + ' godzin'
        }
        if (hours < 1) {
          time = 'niecałą godzinę'
        }
      }
      const timeHtml = '<span class="time">' + time + '</span>'
      // todo, jak w regex ignorować spację?
      var messageWithTime = message.replace(/__TIME__/, timeHtml)

      el.find('.conference-message__body').html(messageWithTime)
      el.find('.conference-message__close').on('click', function(e) {
        e.preventDefault()
        setAsDontShow(el)
        el.hide()
      })

      // el.parent().css({
      //   top: TOP+count*50
      // })

      elements.push(el.parent())
      // el.show()
      count  ++ ;
      
    }
  })
  if(count > 0){
    $('.site-content').append('<div class="conference-message-c"></div>')
    const cont = $('.conference-message-c')
    elements.map(el=>{
      cont.append(el)
      el.find('.conference-message').show();
    })
  }
}

function shouldBeShown(el) {
  const fromD = parseDate(el.data('from'))
  const toD = parseDate(el.data('to'))

  if (!fromD || !toD) {
    return false
  }
  const now = new Date()

  const isAfterFrom = datediff(now, fromD) <= 0
  const isBeforeTo = datediff(now, toD) >= 0

  // sprawdzmy czy jest
  const key = el.data('id')

  var item = sessionStorage.getItem(key)
  return !item && isAfterFrom && isBeforeTo
}

function setAsDontShow(el) {
  // zapisać w session storage
  const key = el.data('id')

  sessionStorage.setItem(key, 1)
}

// new Date("dateString") is browser-dependent and discouraged, so we'll write
// a simple parse function for U.S. date format (which does no error checking)
// 22-12-2018 04:55
function parseDate(str) {
  var parts = str.split(' ')
  var time = parts[1].split(':')
  var mdy = parts[0].split('-')
  return new Date(mdy[2], mdy[1] - 1, mdy[0], time[0], time[1], 0, 0)
}

function datediff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24))
}
function datediffhours(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60))
}
